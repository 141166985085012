import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import Helmet from "react-helmet";
import '../../styles/certificates.css'
import { PageFooter } from "../../components/blog-components";

const Page : React.FunctionComponent = () => {
  const Heading = "TCS Codevita Season 9 Certificate";

  return (
    <div className="certificates-container">
      <Helmet>
        <body className="body"/>
        <title> {Heading} </title>
      </Helmet>

      <div className="certificates-header" >
        <h1> {Heading} </h1>
      </div>
      <div className="certificates-body">
        <div style={{display:'flex', justifyContent:'center'}}>
          <StaticImage src="../../images/tcs-codevita-s9-certificate.png" alt="tcs-codevia-s9-certificate"/>
        </div>
        <br/><br/>
        <p> Below are the screenshot from codevita portal: </p>
        <StaticImage src="../../images/tcs-codevita-s9-result-1.jpeg" alt="tcs-codevia-s9-rank-screenshot"/>
        <br/><br/>
        <p> More about the competition: <a href="https://www.tcs.com/tcs-codevita-wins-new-guinness-world-records-title-worlds-largest-programming-competition"> World’s Largest Programming Competition </a> </p>
      </div>

      <PageFooter iconColor={"#000"} />
    </div>
  )
}

export default Page;